<template>
  <div>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%; table-layout: fixed;">
      <el-table-column
        prop="物件資料"
        label="物件资料"
        :resizable="true">
        <template #default="scope">
          <div v-html="formatNewLines(scope.row.物件資料)"></div>
        </template>
      </el-table-column>
      <el-table-column
        prop="審核結果"
        label="审核结果"
        :resizable="true">
        <template #default="scope">
          <div v-html="formatNewLines(scope.row.審核結果)"></div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right">
        <template #default="scope">
          <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :total="total"
      layout="prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { ElTable, ElTableColumn, ElPagination, ElButton } from 'element-plus';

const tableData = ref([]);
const currentPage = ref(1);
const pageSize = ref(30);
const total = ref(0);

const fetchData = async () => {
  try {
    const response = await axios.get(`https://audit.10794.online/results?page=${currentPage.value}&limit=${pageSize.value}`);
    tableData.value = response.data.results;
    total.value = response.data.total;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const handleSizeChange = (newSize) => {
  pageSize.value = newSize;
  fetchData();
};

const handleCurrentChange = (newPage) => {
  currentPage.value = newPage;
  fetchData();
};

const formatNewLines = (text) => {
  return text.replace(/\n/g, '<br>');
};

const handleEdit = (row) => {
  console.log('Edit:', row);
};

const handleDelete = (row) => {
  console.log('Delete:', row);
};

onMounted(fetchData);
</script>
